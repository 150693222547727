import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_COMPANIES: null,
  CREATE_COMPANY: null,
  GET_COMPANY: null,
  EDIT_COMPANY: null,
  DELETE_COMPANY: null,
  GET_COMPANY_USERS: null,
  ADD_USER_TO_COMPANY: null,
  REMOVE_USER_FROM_COMPANY: null,
  RESET_COMPANY_FORM: null,
  UPDATE_COMPANY_FORM: null,
  SEND_INVITE: null,
  TOGGLE_USER_ADMIN_OF_COMPANY: null,
});

export default types;
