import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allTermInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const optInUsers = (state = allTermInitState, action) => {
  switch (action.type) {
    case `${types.GET_OPT_IN_USERS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_OPT_IN_USERS}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_OPT_IN_USERS}_FAILURE`:
      return assign({}, allTermInitState, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export default combineReducers({
  optInUsers,
});
