import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';
import { setCookie } from 'library/helpers/session';

const initState = null;

const authToken = (state = initState, action) => {
  switch (action.type) {
    case `${types.LOGIN}_SUCCESS`:
      const idToken = get(action, 'data.idToken');
      setCookie(idToken);
      return idToken;
    case `${types.LOGIN}_FAILURE`:
      return null;
    case `${types.LOGOUT}_SUCCESS`:
      return initState;
    default:
      return state;
  }
};

const userInitState = {
  data: [],
  success: null,
  error: null,
};

const currentUser = (state = userInitState, action) => {
  switch (action.type) {
    case `${types.GET_CURRENT_ADMIN}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: null,
            success: true,
          },
      );
    case `${types.LOGOUT}_SUCCESS`:
      return userInitState;
    case `${types.GET_CURRENT_ADMIN}_REQUEST`:
    case `${types.EDIT_ADMIN}_REQUEST`:
    case `${types.DELETE_ADMIN}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
      });
    case `${types.EDIT_ADMIN}_SUCCESS`:
    case `${types.DELETE_ADMIN}_SUCCESS`:
      return assign({}, state, {
        data: assign(state.data, action.data),
        success: true,
        error: false,
      });
    case `${types.EDIT_ADMIN}_FAILURE`:
    case `${types.DELETE_ADMIN}_FAILURE`:
    case `${types.GET_CURRENT_ADMIN}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error,
      });
    default:
      return state;
  }
};

export default combineReducers({
  authToken,
  currentUser,
});
