import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_POLICIES: null,
  GET_POLICY: null,
  CREATE_POLICY: null,
  EDIT_POLICY: null,
  DELETE_POLICY: null,
  UPDATE_POLICY_FORM: null,
  RESET_POLICY_FORM: null,
});

export default types;
