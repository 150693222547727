import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allLicenseInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allLicenseInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_LICENSES}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_LICENSES}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_LICENSES}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const initialLicenseFormState = {
  email: '',
  session: '',
  maxAttendees: '',
  type: '',
  isActive: false,
  success: false,
  created: false,
  updated: false,
  deleted: false,
  error: false,
};

const licenseForm = (state = initialLicenseFormState, action) => {
  switch (action.type) {
    case `${types.RESET_LICENSE_FORM}`:
    case `${types.GET_LICENSE}_REQUEST`:
      return initialLicenseFormState;
    case `${types.GET_LICENSE}_SUCCESS`:
    case `${types.UPDATE_LICENSE_FORM}`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        deleted: false,
        error: false,
        ...action.data,
      });
    case `${types.CREATE_LICENSE}_REQUEST`:
    case `${types.EDIT_LICENSE}_REQUEST`:
    case `${types.DELETE_LICENSE}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        deleted: false,
        error: false,
      });
    case `${types.CREATE_LICENSE}_SUCCESS`:
      return assign({}, {
        ...action.data,
        created: true,
        success: true,
        error: null,
      });
    case `${types.EDIT_LICENSE}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.DELETE_LICENSE}_SUCCESS`:
      return assign({}, {
        ...action.data,
        success: true,
        deleted: true,
        error: null,
      });
    case `${types.GET_LICENSE}_FAILURE`:
    case `${types.CREATE_LICENSE}_FAILURE`:
    case `${types.DELETE_LICENSE}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_LICENSE}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  all,
  licenseForm,
});
