import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_TERMS: null,
  GET_TERM: null,
  CREATE_TERM: null,
  EDIT_TERM: null,
  DELETE_TERM: null,
  UPDATE_TERM_FORM: null,
  RESET_TERM_FORM: null,
});

export default types;
