import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_ADMINS: null,
  GET_ADMIN: null,
  CREATE_ADMIN: null,
  EDIT_ADMIN: null,
  DELETE_ADMIN: null,
  UPDATE_ADMIN_FORM: null,
  RESET_ADMIN_FORM: null,
});

export default types;
