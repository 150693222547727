import { createStore, applyMiddleware } from 'redux';
import { createWrapper } from 'next-redux-wrapper';
import { persistStore, persistReducer } from 'redux-persist';
import rootReducer from './root-reducer';
import middlewares from '../middlewares';

const makeConfiguredStore = (reducer) => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension');
    return createStore(reducer, {}, composeWithDevTools(applyMiddleware(...middlewares)));
  }
  return createStore(reducer, {}, applyMiddleware(...middlewares));
};
/**
 * Create redux store
 *
 * @return  {Store}
 */
const makeStore = () => {
  const isServer = typeof window === 'undefined';
  if (isServer) {
    // create server side store
    return makeConfiguredStore(rootReducer);
  } else {
    // client side store
    const storage = require('redux-persist/lib/storage').default;

    const persistConfig = {
      key: 'root',
      storage,
      whitelist: [
        'adminForm',
        'all',
        'currentUser',
        'auth',
        'creatorForm',
        'licenseForm',
        'sessionForm',
        'waitlist',
        'supporterForm',
        'categoryForm',
      ],
    };
    const persistedReducer = persistReducer(persistConfig, rootReducer); // Create a new reducer with our existing reducer

    const store = makeConfiguredStore(persistedReducer);
    store.__persistor = persistStore(store);
    return store;
  }
};

export const reduxWrapper = createWrapper(makeStore);
