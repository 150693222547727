import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_CATEGORIES: null,
  GET_CATEGORY: null,
  CREATE_CATEGORY: null,
  EDIT_CATEGORY: null,
  DELETE_CATEGORY: null,
  UPDATE_CATEGORY_FORM: null,
  RESET_CATEGORY_FORM: null,
});

export default types;
