'use strict';
/**
 * Promise middleware
 *
 * @return  {Promise}
 */
export default function promiseMiddleware({ getState }) {
  return (next) => async (action) => {
    let { promise } = action;
    const {
      type,
      ...rest
    } = action;
    if (!promise) return next(action);

    if (promise.execute) {
      promise = promise.execute(getState()?.Auth?.auth?.idToken || '');
    }

    const SUCCESS = type + '_SUCCESS';
    const REQUEST = type + '_REQUEST';
    const FAILURE = type + '_FAILURE';

    next({
      ...rest,
      type: REQUEST,
    });

    try {
      const json = await promise;

      next({
        ...rest,
        data: json,
        type: SUCCESS,
      });

      return true;
    } catch (error) {
      next({
        ...rest,
        error,
        type: FAILURE,
      });

      return false;
    }
  };
}
