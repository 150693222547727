import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
// Add the Performance Monitoring library
import 'firebase/compat/performance';
import isoConfig from 'config/firebase.config';

export const firebaseApp = init();
/**
 * init Firebase
 */
function init() {
  if (!firebase.apps.length) {
    firebase.initializeApp(isoConfig);
    // ensure client-side and initialize Performance Monitoring
    typeof window !== 'undefined' && firebase.performance();
  } else {
    firebase.app();
  }
}
export const auth = firebase.auth();

// uncomment if to use local Google Auth emulator
// if (process.env.NODE_ENV !== 'production') {
//   firebase.auth().useEmulator('http://localhost:9099/');
// }

export const db = firebase.firestore();

export default firebase;
