import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_REVISIONS: null,
  GET_ALL_PENDING_REVISIONS: null,
  GET_REVISION: null,
  EDIT_REVISION: null,
  REVERT_REVISION: null,
  UPDATE_REVISION_FORM: null,
  RESET_REVISION_FORM: null,
});

export default types;
