import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allAdminInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allAdminInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_ADMINS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_ADMINS}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_ADMINS}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const initialAdminFormState = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  success: false,
  created: false,
  updated: false,
  error: false,
};

const adminForm = (state = initialAdminFormState, action) => {
  switch (action.type) {
    case `${types.RESET_ADMIN_FORM}`:
    case `${types.GET_ADMIN}_REQUEST`:
      return initialAdminFormState;
    case `${types.GET_ADMIN}_SUCCESS`:
    case `${types.UPDATE_ADMIN_FORM}`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.CREATE_ADMIN}_REQUEST`:
    case `${types.EDIT_ADMIN}_REQUEST`:
    case `${types.DELETE_ADMIN}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
      });
    case `${types.CREATE_ADMIN}_SUCCESS`:
      return assign({}, {
        ...action.data,
        created: true,
        success: true,
        error: null,
      });
    case `${types.EDIT_ADMIN}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.DELETE_ADMIN}_SUCCESS`:
      return assign({}, {
        ...action.data,
        success: true,
        error: null,
      });
    case `${types.GET_ADMIN}_FAILURE`:
    case `${types.CREATE_ADMIN}_FAILURE`:
    case `${types.DELETE_ADMIN}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_ADMIN}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  all,
  adminForm,
});
