import keyMirror from 'keymirror';

const types = keyMirror({
  GET_HOMEPAGE: null,
  EDIT_HOMEPAGE: null,
  UPDATE_HOMEPAGE_FORM: null,
  RESET_HOMEPAGE_FORM: null,
});

export default types;
