import { combineReducers } from 'redux';
import types from './types';
import revisionTypes from '../revision/types';
import { get, assign } from 'lodash';

const allSessionInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allSessionInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_SESSIONS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_SESSIONS}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_SESSIONS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const nextWeek = (state = allSessionInitState, action) => {
  switch (action.type) {
    case `${types.GET_UPCOMING_SESSION}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_UPCOMING_SESSION}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_UPCOMING_SESSION}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const waitlist = (state = allSessionInitState, action) => {
  switch (action.type) {
    case `${types.RESET_SESSION_FORM}`:
      return allSessionInitState;
    case `${types.GET_ALL_WAITLIST_USERS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_WAITLIST_USERS}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data ?? {},
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_WAITLIST_USERS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const attendees = (state = allSessionInitState, action) => {
  switch (action.type) {
    case `${types.RESET_SESSION_FORM}`:
      return allSessionInitState;
    case `${types.GET_ALL_ATTENDEES}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_ATTENDEES}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data ?? {},
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_ATTENDEES}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const invoice = (state = allSessionInitState, action) => {
  switch (action.type) {
    case `${types.RESET_SESSION_FORM}`:
      return allSessionInitState;
    case `${types.GET_SESSION_INVOICE}_REQUEST`:
    case `${types.UPDATE_SESSION_INVOICE}_REQUEST`:
      return assign(state, {
        success: false,
        updated: false,
        error: false,
        loading: true,
      });
    case `${types.GET_SESSION_INVOICE}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data ?? {},
            success: true,
            loading: false,
          },
      );
    case `${types.UPDATE_SESSION_INVOICE}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data ?? {},
            updated: true,
            loading: false,
          },
      );
    case `${types.GET_SESSION_INVOICE}_FAILURE`:
    case `${types.UPDATE_SESSION_INVOICE}_FAILURE`:
      return assign({}, state, {
        success: false,
        updated: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const history = (state = allSessionInitState, action) => {
  switch (action.type) {
    case `${types.RESET_SESSION_FORM}`:
      return allSessionInitState;
    case `${types.GET_SESSION_HISTORY}_REQUEST`:
    case `${revisionTypes.REVERT_REVISION}_REQUEST`:
      return assign(state, {
        success: false,
        updated: false,
        error: false,
      });
    case `${types.GET_SESSION_HISTORY}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data,
            success: true,
          },
      );
    case `${revisionTypes.REVERT_REVISION}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data,
            updated: true,
          },
      );
    case `${types.GET_SESSION_HISTORY}_FAILURE`:
    case `${revisionTypes.REVERT_REVISION}_FAILURE`:
      return assign({}, state, {
        error: action.error,
      });
    default:
      return state;
  }
};

const initialSessionFormState = {
  webinarID: '',
  title: '',
  shortDescription: '',
  longDescription: '',
  creator: null,
  showRunner: null,
  mainImage: '',
  verticalImage: '',
  type: null,
  video: '',
  videoDescription: '',
  status: 'upcoming',
  startDate: null,
  duration: '',
  tags: [],
  category: null,
  subcategory: null,
  timezone: null,
  ticketPrice: null,
  totalTickets: null,
  totalTicketsSold: 0,
  isVisible: true,
  isAnnouncement: false,
  isFeatured: false,
  isSeries: false,
  disableTicketsAvailEmail: false,
  participants: [],
  success: false,
  created: false,
  updated: false,
  deleted: false,
  error: false,
};

const sessionForm = (state = initialSessionFormState, action) => {
  switch (action.type) {
    case `${types.RESET_SESSION_FORM}`:
      return initialSessionFormState;
    case `${types.GET_SESSION}_REQUEST`:
      return assign(state, {
        success: false,
        created: false,
        updated: false,
        deleted: false,
        error: false,
      });
    case `${types.GET_SESSION}_SUCCESS`:
    case `${types.UPDATE_SESSION_FORM}`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        deleted: false,
        error: false,
        ...action.data,
      });
    case `${types.CREATE_SESSION}_REQUEST`:
    case `${types.CREATE_TEST_SESSION}_REQUEST`:
    case `${types.EDIT_SESSION}_REQUEST`:
    case `${types.DELETE_SESSION}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        deleted: false,
        error: false,
      });
    case `${types.CREATE_SESSION}_SUCCESS`:
    case `${types.CREATE_TEST_SESSION}_SUCCESS`:
      return assign({}, {
        ...action.data,
        created: true,
        success: true,
        error: null,
      });
    case `${types.EDIT_SESSION}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.DELETE_SESSION}_SUCCESS`:
      return assign({}, {
        ...action.data,
        deleted: true,
        error: null,
      });
    case `${types.GET_SESSION}_FAILURE`:
    case `${types.CREATE_SESSION}_FAILURE`:
    case `${types.CREATE_TEST_SESSION}_FAILURE`:
    case `${types.DELETE_SESSION}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_SESSION}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  all,
  waitlist,
  sessionForm,
  invoice,
  attendees,
  history,
  nextWeek,
});
