import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_SUPPORTERS: null,
  GET_SUPPORTER: null,
  CREATE_SUPPORTER: null,
  EDIT_SUPPORTER: null,
  DELETE_SUPPORTER: null,
  UPDATE_SUPPORTER_FORM: null,
  RESET_SUPPORTER_FORM: null,
});

export default types;
