import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allSupporterInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allSupporterInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_SUPPORTERS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_SUPPORTERS}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_SUPPORTERS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const initialSupporterFormState = {
  firstName: '',
  lastName: '',
  phone: '',
  displayName: '',
  email: '',
  avatar: '',
  hasEmailNotifications: true,
  hasSmsNotifications: true,
  isBanned: false,
  managerFor: [],
  success: false,
  created: false,
  updated: false,
  error: false,
};

const supporterForm = (state = initialSupporterFormState, action) => {
  switch (action.type) {
    case `${types.RESET_SUPPORTER_FORM}`:
    case `${types.GET_SUPPORTER}_REQUEST`:
      return initialSupporterFormState;
    case `${types.GET_SUPPORTER}_SUCCESS`:
    case `${types.UPDATE_SUPPORTER_FORM}`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.CREATE_SUPPORTER}_REQUEST`:
    case `${types.EDIT_SUPPORTER}_REQUEST`:
    case `${types.DELETE_SUPPORTER}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
      });
    case `${types.CREATE_SUPPORTER}_SUCCESS`:
      return assign({}, {
        ...action.data,
        created: true,
        success: true,
        error: null,
      });
    case `${types.EDIT_SUPPORTER}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.DELETE_SUPPORTER}_SUCCESS`:
      return assign({}, {
        ...action.data,
        success: true,
        error: null,
      });
    case `${types.GET_SUPPORTER}_FAILURE`:
    case `${types.CREATE_SUPPORTER}_FAILURE`:
    case `${types.DELETE_SUPPORTER}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_SUPPORTER}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  all,
  supporterForm,
});
