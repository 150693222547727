import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allRevisionInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allRevisionInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_REVISIONS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_REVISIONS}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_REVISIONS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const pending = (state = allRevisionInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_PENDING_REVISIONS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_PENDING_REVISIONS}_SUCCESS`:
      return assign(
          {},
          {
            pending: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_PENDING_REVISIONS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const initialRevisionFormState = {
  history: [],
  changes: {},
  notes: '',
  status: '',
  reviewerID: '',
  success: false,
  updated: false,
  error: false,
};

const revisionForm = (state = initialRevisionFormState, action) => {
  switch (action.type) {
    case `${types.RESET_REVISION_FORM}`:
    case `${types.GET_REVISION}_REQUEST`:
      return initialRevisionFormState;
    case `${types.GET_REVISION}_SUCCESS`:
    case `${types.UPDATE_REVISION_FORM}`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.EDIT_REVISION}_REQUEST`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: false,
      });
    case `${types.EDIT_REVISION}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.GET_REVISION}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_REVISION}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  all,
  pending,
  revisionForm,
});
