import { combineReducers } from 'redux';
import types from './types';
import { assign } from 'lodash';


const initialWatchFormState = {
  carousels: [],
  success: false,
  updated: false,
  error: false,
};

const watchForm = (state = initialWatchFormState, action) => {
  switch (action.type) {
    case `${types.GET_WATCH}_REQUEST`:
      return initialWatchFormState;
    case `${types.GET_WATCH}_SUCCESS`:
    case `${types.UPDATE_WATCH_FORM}`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.EDIT_WATCH}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.GET_WATCH}_FAILURE`:
    case `${types.EDIT_WATCH}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  watchForm,
});
