import { combineReducers } from 'redux';
import { HYDRATE } from 'next-redux-wrapper';
import Auth from 'redux/auth/reducer';
import App from 'redux/app/reducer';
import Admin from 'redux/admin/reducer';
import Creator from 'redux/creator/reducer';
import Supporter from 'redux/supporter/reducer';
import License from 'redux/license/reducer';
import Category from 'redux/category/reducer';
import Term from 'redux/term/reducer';
import Policy from 'redux/policy/reducer';
import Session from 'redux/session/reducer';
import Revision from 'redux/revision/reducer';
import Homepage from 'redux/homepage/reducer';
import Tool from 'redux/tool/reducer';
import Lesson from 'redux/lesson/reducer';
import Watch from 'redux/watch/reducer';
import Dashboard from 'redux/dashboard/reducer';
import Company from 'redux/company/reducer';

const appReducer = combineReducers({
  Auth,
  App,
  Admin,
  Creator,
  Supporter,
  License,
  Category,
  Term,
  Policy,
  Session,
  Revision,
  Homepage,
  Tool,
  Lesson,
  Watch,
  Dashboard,
  Company,
});

let hydrated = false;
const rootReducer = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      if (!hydrated) {
        hydrated = typeof window !== 'undefined';
        return {
          ...state,
          ...action.payload,
        };
      }
      return state;
    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
