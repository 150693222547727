import { combineReducers } from 'redux';
import types from './types';
import { assign } from 'lodash';

const vodViewsInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const vodViews = (state = vodViewsInitState, action) => {
  switch (action.type) {
    case `${types.GET_VOD_VIEWS}_REQUEST`:
      return assign(state, {
        success: false,
        updated: false,
        error: false,
      });
    case `${types.GET_VOD_VIEWS}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data,
            success: true,
          },
      );
    case `${types.GET_VOD_VIEWS}_FAILURE`:
      return assign({}, state, {
        error: action.error,
      });
    default:
      return state;
  }
};

const sessionInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const sessions = (state = sessionInitState, action) => {
  switch (action.type) {
    case `${types.GET_SESSIONS}_REQUEST`:
      return assign(state, {
        success: false,
        updated: false,
        error: false,
      });
    case `${types.GET_SESSIONS}_SUCCESS`:
      return assign(
          {},
          {
            data: action?.data,
            success: true,
          },
      );
    case `${types.GET_SESSIONS}_FAILURE`:
      return assign({}, state, {
        error: action.error,
      });
    default:
      return state;
  }
};


export default combineReducers({
  vodViews,
  sessions,
});
