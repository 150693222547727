import React from 'react';
import Router from 'next/router';
import App from 'next/app';
import ThemeProvider from 'containers/ThemeProvider';
import { PersistGate } from 'redux-persist/integration/react';
import { ReactReduxContext } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'antd/dist/antd.css';
import { reduxWrapper } from '../redux/store';
import 'video.js/dist/video-js.css';
// eslint-disable-next-line no-unused-vars
import firebase from '../library/firebase/firebase'; // import to init

/**
 * CustomApp - Application class
 */
class CustomApp extends App {
  /**
   * Component mount event
   */
  async componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      const sentryEnv = process.env.GCLOUD_PROJECT === 'bright-live-production' ? 'production' : 'stage';
      Sentry.init({
        dsn: 'https://c9ba9fae53af4afe98a6fe933786fbe8@o550310.ingest.sentry.io/5673765',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.5,
        environment: sentryEnv,
      });
    }
    const { default: NProgress } = await import('nprogress');
    Router.events.on('routeChangeStart', (url) => {
      NProgress.start();
    });

    Router.events.on('routeChangeComplete', (url) => {
      NProgress.done();
    });

    Router.events.on('routeChangeError', () => {
      NProgress.done();
    });
  }

  /**
   * Render
   *
   * @return  {JSX.Element}
   */
  render() {
    const { Component, pageProps } = this.props;
    return (
      <ThemeProvider>
        <ReactReduxContext.Consumer>
          {({ store }) => (
            <PersistGate persistor={store.__persistor} loading={<></>}>
              <Component {...pageProps} />
            </PersistGate>
          )}
        </ReactReduxContext.Consumer>
      </ThemeProvider>
    );
  }
}

export default reduxWrapper.withRedux(CustomApp);
