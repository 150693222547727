import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allCategoryInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allCategoryInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_CATEGORIES}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_CATEGORIES}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_CATEGORIES}_FAILURE`:
      return assign({}, allCategoryInitState, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const initialCategoryFormState = {
  name: '',
  description: '',
  type: '',
  success: false,
  created: false,
  updated: false,
  error: false,
};

const categoryForm = (state = initialCategoryFormState, action) => {
  switch (action.type) {
    case `${types.RESET_CATEGORY_FORM}`:
    case `${types.GET_CATEGORY}_REQUEST`:
      return initialCategoryFormState;
    case `${types.GET_CATEGORY}_SUCCESS`:
    case `${types.UPDATE_CATEGORY_FORM}`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.CREATE_CATEGORY}_REQUEST`:
    case `${types.EDIT_CATEGORY}_REQUEST`:
    case `${types.DELETE_CATEGORY}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
      });
    case `${types.CREATE_CATEGORY}_SUCCESS`:
      return assign({}, {
        ...action.data,
        created: true,
        success: true,
        error: null,
      });
    case `${types.EDIT_CATEGORY}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.DELETE_CATEGORY}_SUCCESS`:
      return assign({}, {
        ...action.data,
        success: true,
        error: null,
      });
    case `${types.GET_CATEGORY}_FAILURE`:
    case `${types.CREATE_CATEGORY}_FAILURE`:
    case `${types.DELETE_CATEGORY}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_CATEGORY}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  all,
  categoryForm,
});
