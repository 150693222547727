import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allCompaniesInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allCompaniesInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_COMPANIES}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_COMPANIES}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_COMPANIES}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const initialCompanyFormState = {
  active: false,
  emailDomain: '',
  licenses: 0,
  licensesUsed: 0,
  logo: '',
  logoDark: '',
  maxPrice: 0,
  name: '',
  slug: '',
  success: false,
  created: false,
  updated: false,
  error: false,
};

const companyForm = (state = initialCompanyFormState, action) => {
  switch (action.type) {
    case `${types.RESET_COMPANY_FORM}`:
    case `${types.GET_COMPANY}_REQUEST`:
      return initialCompanyFormState;
    case `${types.GET_COMPANY}_SUCCESS`:
    case `${types.UPDATE_COMPANY_FORM}`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.CREATE_COMPANY}_REQUEST`:
    case `${types.EDIT_COMPANY}_REQUEST`:
    case `${types.DELETE_COMPANY}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        error: false,
      });
    case `${types.CREATE_COMPANY}_SUCCESS`:
      return assign({}, {
        ...action.data,
        created: true,
        success: true,
        error: null,
      });
    case `${types.EDIT_COMPANY}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.DELETE_COMPANY}_SUCCESS`:
      return assign({}, {
        ...action.data,
        success: true,
        error: null,
      });
    case `${types.GET_COMPANY}_FAILURE`:
    case `${types.CREATE_COMPANY}_FAILURE`:
    case `${types.DELETE_COMPANY}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_SUPPORTER}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

const allCompanyUsersInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const allUsers = (state = allCompanyUsersInitState, action) => {
  switch (action.type) {
    case `${types.GET_COMPANY_USERS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_COMPANY_USERS}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_COMPANY_USERS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const sendInviteInitState = {
  data: null,
  success: false,
  error: '',
  loading: false,
  removed: false,
};

const sendInvite = (state = sendInviteInitState, action) => {
  switch (action.type) {
    case `${types.SEND_INVITE}_REQUEST`:
      return sendInviteInitState;
    case `${types.SEND_INVITE}_SUCCESS`:
      return assign({}, state, {
        success: true,
        created: false,
        updated: false,
        error: '',
        removed: false,
        data: action.data,
      });
    case `${types.SEND_INVITE}_FAILURE`:
      return assign({}, state, {
        data: false,
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};

export default combineReducers({
  all,
  allUsers,
  companyForm,
  sendInvite,
});
