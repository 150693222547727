import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_LESSONS: null,
  GET_LESSON: null,
  CREATE_LESSON: null,
  EDIT_LESSON: null,
  DELETE_LESSON: null,
  UPDATE_LESSON_FORM: null,
  RESET_LESSON_FORM: null,
});

export default types;
