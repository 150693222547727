import { combineReducers } from 'redux';
import types from './types';
import { get, assign } from 'lodash';

const allLessonInitState = {
  data: [],
  success: false,
  error: false,
  loading: false,
};

const all = (state = allLessonInitState, action) => {
  switch (action.type) {
    case `${types.GET_ALL_LESSONS}_REQUEST`:
      return assign(state, {
        success: false,
        error: false,
        loading: true,
      });
    case `${types.GET_ALL_LESSONS}_SUCCESS`:
      return assign(
          {},
          {
            data: get(action, 'data', {}),
            error: false,
            success: true,
            loading: false,
          },
      );
    case `${types.GET_ALL_LESSONS}_FAILURE`:
      return assign({}, state, {
        success: false,
        loading: false,
        error: action.error,
      });
    default:
      return state;
  }
};

const initialLessonFormState = {
  title: '',
  creatorDescription: '',
  description: '',
  tags: [],
  videoStorageUrl: '',
  playbackID: '',
  verticalImage: '',
  mainImage: '',
  categoryID: null,
  subcategoryID: null,
  creatorID: null,
  sessionID: null,
  isVisible: false,
  success: false,
  created: false,
  updated: false,
  deleted: false,
  error: false,
};

const lessonForm = (state = initialLessonFormState, action) => {
  switch (action.type) {
    case `${types.RESET_LESSON_FORM}`:
    case `${types.GET_LESSON}_REQUEST`:
      return initialLessonFormState;
    case `${types.GET_LESSON}_SUCCESS`:
    case `${types.UPDATE_LESSON_FORM}`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        deleted: false,
        error: false,
        ...action.data,
      });
    case `${types.CREATE_LESSON}_REQUEST`:
    case `${types.EDIT_LESSON}_REQUEST`:
    case `${types.DELETE_LESSON}_REQUEST`:
      return assign({}, state, {
        success: false,
        created: false,
        updated: false,
        deleted: false,
        error: false,
      });
    case `${types.CREATE_LESSON}_SUCCESS`:
      return assign({}, {
        ...action.data,
        created: true,
        success: true,
        error: null,
      });
    case `${types.EDIT_LESSON}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.DELETE_LESSON}_SUCCESS`:
      return assign({}, {
        ...action.data,
        success: true,
        deleted: true,
        error: null,
      });
    case `${types.GET_LESSON}_FAILURE`:
    case `${types.CREATE_LESSON}_FAILURE`:
    case `${types.DELETE_LESSON}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_LESSON}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  all,
  lessonForm,
});
