import { combineReducers } from 'redux';
import types from './types';
import { assign } from 'lodash';


const initialHomepageFormState = {
  heroSessions: [],
  featuredSessions: {
    sessions: [],
    title: '',
  },
  success: false,
  updated: false,
  error: false,
};

const homepageForm = (state = initialHomepageFormState, action) => {
  switch (action.type) {
    case `${types.RESET_HOMEPAGE_FORM}`:
    case `${types.GET_HOMEPAGE}_REQUEST`:
      return initialHomepageFormState;
    case `${types.GET_HOMEPAGE}_SUCCESS`:
    case `${types.UPDATE_HOMEPAGE_FORM}`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: false,
        ...action.data,
      });
    case `${types.EDIT_HOMEPAGE}_REQUEST`:
      return assign({}, state, {
        success: false,
        updated: false,
        error: false,
      });
    case `${types.EDIT_HOMEPAGE}_SUCCESS`:
      return assign({}, {
        ...action.data,
        updated: true,
        success: true,
        error: null,
      });
    case `${types.GET_HOMEPAGE}_FAILURE`:
      return assign({}, state, {
        data: [],
        success: false,
        error: action.error.message,
      });
    case `${types.EDIT_HOMEPAGE}_FAILURE`:
      return assign({}, state, {
        success: false,
        error: action.error.message,
      });
    default:
      return state;
  }
};


export default combineReducers({
  homepageForm,
});
