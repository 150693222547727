import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_SESSIONS: null,
  GET_ALL_WAITLIST_USERS: null,
  GET_ALL_ATTENDEES: null,
  GET_SESSION_INVOICE: null,
  UPDATE_SESSION_INVOICE: null,
  GET_SESSION_HISTORY: null,
  GET_SESSION: null,
  CREATE_SESSION: null,
  EDIT_SESSION: null,
  DELETE_SESSION: null,
  UPDATE_SESSION_FORM: null,
  RESET_SESSION_FORM: null,
  CREATE_TEST_SESSION: null,
  GET_UPCOMING_SESSION: null,
});

export default types;
