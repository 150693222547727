import keyMirror from 'keymirror';

const types = keyMirror({
  LOGIN: null,
  LOGOUT: null,
  GET_CURRENT_ADMIN: null,
  EDIT_ADMIN: null,
  DELETE_ADMIN: null,
});

export default types;
