import keyMirror from 'keymirror';

const types = keyMirror({
  GET_ALL_LICENSES: null,
  GET_LICENSE: null,
  CREATE_LICENSE: null,
  EDIT_LICENSE: null,
  DELETE_LICENSE: null,
  UPDATE_LICENSE_FORM: null,
  RESET_LICENSE_FORM: null,
});

export default types;
